<template>
  <div class="bkColor">
    <headTitle :title="title"></headTitle>
    <van-search v-model="str" input-align="center" placeholder="请输入发起人或项目名称关键字" shape="round" show-action
      @search="onSearch" @cancel="onCancel" style="margin-top: 2vh" />
    <div class="search">
      <div>审核类型：</div>
      <van-dropdown-menu>
        <van-dropdown-item v-model="reviewedType" :options="typeOption" @change="changeType" />
      </van-dropdown-menu>
      <div class="searchTitle">审核状态：</div>
      <van-dropdown-menu>
        <van-dropdown-item v-model="queryFrom.status" :options="stateOption" @change="changePjState" />
      </van-dropdown-menu>
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
          finished-text="没有更多了" @load="getAuditList">
          <!-- <van-cell v-for="item in list" :key="item"  /> -->
          <div class="listCell" v-for="item in list" :key="item.id" @click="goDetails(item.id, item.reviewedType)">
            <div class="cellName">{{ item.projectName || item.targetName + '的' + getReviewedText(item.reviewedType) +
              "(非项目)"
            }}</div>
            <div class="cellText"> 流水号：{{ item.number }}</div>
            <div class="cellText">
              审核意见：{{ getReviewedStatusText(item.status) }}
            </div>
            <div class="cellText">审核类型：{{ getReviewedText(item.reviewedType) }}</div>
            <div class="cellText">创建时间：{{ item.createTime }}</div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import { Search, Toast, List, PullRefresh, Cell } from "vant";

import { reviewedText, reviewedStatusText } from '../units/units'
import { reviewedList } from "../api/reviewed";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
export default {
  name: "auditList",
  components: {
    headTitle,
  },
  data() {
    return {
      title: "审核列表",
      str: "",
      stateOption: [
        { text: "全部", value: null },
        // { text: "审核中", value: 0 },   
        { text: "审核中", value: 3 },
        { text: "审核已通过", value: 1 },
        { text: "审核拒绝", value: 2 },

        { text: "审核中途取消", value: 4 },

      ],
      typeOption: [
        { text: "全部", value: null },
        // { text: "项目", value: 1 },
        // { text: "收款", value: 2 },
        { text: "付款", value: 3 },
        { text: "报销", value: 4 },
        { text: "非项目付款", value: 5 },
        { text: "非项目报销", value: 6 },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      laoclUrl: "",
      reviewedType: null,
      queryFrom: {
        reviewedType: null,
        status: null,
        targetName: "",
        additional: null,
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from)
    if (from.path == '/auditDetails') {
      next(vm => {
        vm.onCancel()
      })
    }
    next()
  },
  methods: {
    getAuditList() {
      this.loading = true;
      reviewedList(this.queryFrom).then((res) => {
        this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        if (
          res.data.total <=
          (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
        ) {
          this.finished = true;
        }
      });
    },
    //搜索框查询
    onSearch(val) {
      this.queryFrom.targetName = val
      this.onRefresh()
      // this.$router.push({ path: '/preview', query: { url: val } })
    },
    //搜索框取消
    onCancel() {
      this.queryFrom.targetName = "";
      this.queryFrom.reviewedType = null;
      this.queryFrom.status = null;
      this.queryFrom.additional = null
      this.additional = null
      this.onRefresh();
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.queryFrom.pageNum = 1;
      this.list = []
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.getAuditList();
    },
    //项目详情
    goDetails(id, type) {
      this.$router.push({ path: '/auditDetails', query: { id: id, type: type } });
    },
    getReviewedText(t) {
      return reviewedText(t)
    },
    getReviewedStatusText(t) {
      return reviewedStatusText(t)
    },
    changePjState(val) {
      this.queryFrom.status = val;
      this.onRefresh();
    },
    changeType(val) {
      this.reviewedType = val
      switch (val) {
        case 3:
          break
        case 4:
          break
        case 5:
          this.queryFrom.reviewedType = 3;
          this.queryFrom.additional = 1
          this.onRefresh();
          return
        case 6:
          this.queryFrom.reviewedType = 4;
          this.queryFrom.additional = 1
          this.onRefresh();
          return
      }
      this.queryFrom.reviewedType = val;
      this.queryFrom.additional = 0
      this.onRefresh();
    }
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;
}

.search {
  width: 100vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  background: #fff;
  font-size: 15px;
  line-height: 8vh;
}

.searchTitle {
  margin-left: 6vw;
}

.list {
  margin-top: 2vh;

  /deep/.van-pull-refresh {
    min-height: 71vh;
  }
}

.listCell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 5vw 1vh;
  background: #fff;
  text-align: left;
}

.cellName {
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  border-top: 2px solid #f6f6f6;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

/deep/ .van-list :first-of-type.listCell .cellName {
  border-top: unset;
}

.cellText {
  width: 70%;
  color: #797979;
  margin-bottom: 0.5vh;
  font-size: 0.7rem;
  word-break: break-all;
  line-height: 1;
}

.addBtn {
  width: 70px;
  height: 70px;
  position: fixed;
  top: 77%;
  left: 77%;

  img {
    width: 70px;
    height: 70px;
  }
}
</style>